@import "./../../../assets/css/variables.scss";

.modalFramedType {
  position: relative;
  width: 100%;
  max-width: 950px;
  max-height: 87vh;
  padding: 60px 20px;
  background-color: #fff;
  @media screen and (max-width: $breakpoint-mob-max) {
    max-height: calc(100vh - 50px);
    padding: 25px 10px;
  }

  .content {
    width: 100%;
    max-width: 680px;
    margin: 0 auto;
  }

  .title {
    margin: 0 0 30px 0;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    color: #000000;
    text-align: center;
    @media screen and (max-width: $breakpoint-mob-max) {
      font-size: 24px;
      line-height: 28px;
      margin: 0 0 25px 0;
    }
  }
  .descr {
    margin: 0 0 30px 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    text-align: center;
    @media screen and (max-width: $breakpoint-mob-max) {
      margin: 0 0 16px 0;
    }
  }
  .formContent {
    [class*="radioOptionsLabel"] {
      display: none;
    }
    [class*="radioOptionsList"] {
      grid-template-columns: repeat(auto-fill, minmax(100px, 200px));
      max-width: 415px;
      margin: 0 auto;
      @media screen and (max-width: $breakpoint-mob-max) {
        grid-template-columns: repeat(auto-fill, minmax(100px, 45%));
        justify-content: center;
      }
    }
  }
  .btnsWrap {
    display: flex;
    justify-content: center;
  }
}