@import "./../../../assets/css/variables.scss";

.wrap {
  width: 100%;
  margin-bottom: 3vh;
}

.title {
  margin: 0 0 2.23vh 0;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  @media screen and (max-width: $breakpoint-mob-max) {
    font-size: 20px;
    line-height: 24px;
  }
  @media screen and (max-width: $breakpoint-IPhone-max) {
    font-size: 16px;
    line-height: 19px;
  }
}

.buttonsWrap {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  input {
    width: 0;
    height: 0;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    &:checked {
      + label {
        background: #171E35;
        color: #FFFFFF;
      }
    }
  }
  label {
    padding: 8px 26px;
    background: #FFFFFF;
    border-radius: 2px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.2);
    transition: all .2s ease-in-out;
    &:first-of-type {
      border-radius: 2px 0 0 2px;
    }
    &:last-of-type {
      border-radius: 0px 2px 2px 0px;
    }
    @media screen and (max-width: $breakpoint-IPhone-max) {
      padding: 5px 20px;
    }
  }
}