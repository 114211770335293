@import "./../../assets/css/variables.scss";

.mobHeader {
  display: none;
  grid-area: header;
  align-items: center;
  justify-content: center;
  background-color: #1F2944;
  @media screen and (max-width: $breakpoint-mob-max) {
    display: flex;
  }
  .mainMenuLogoWrap {
    font-size: 0;
  }
}

.pageGrid {
  display: grid;
  grid-template-columns: 230px minmax(50%, auto);
  grid-template-rows: 100% 100%;
  grid-template-areas: "sidebar content";
  grid-gap: 0;
  width: 100vw;
  height: 100vh;
  font-family: "ProximaNova", sans-serif;
  @media screen and (max-width: $breakpoint-mob-max) {
    grid-template-columns: 100%;
    grid-template-rows: 75px minmax(2vh, auto) minmax(66px, auto);
    grid-template-areas:
      "header"
      "content"
      "sidebar";
  }
}

.mainSidebar {
  grid-area: sidebar;
  padding: 20px 20px 16px;
  background-color: #1f2944;
  position: relative;
  @media screen and (max-width: $breakpoint-mob-max) {
    overflow: hidden;
    padding: 0;
  }
}

.sceneContent {
  position: relative;
  grid-area: content;
}