@import "./../../../../../assets/css/variables.scss";

.productsList {
  width: 100%;
  .blockTitle {
    background-color: #1f2944;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
    padding: 6px;
    padding-left: 16px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
    @media screen and (max-width: $breakpoint-mob-max) {
      font-size: 16px;
      line-height: 1;
      margin-bottom: 15px;
    }
  }
  table {
    width: 100%;
    border-collapse: collapse;
    font-size: 12px;
  }
  table th  {
    background: #e5e4e4;
    padding-top: 8px;
    padding-bottom: 8px;
    font-weight: 400;
  }
  table th:nth-child(1),
  table th:nth-child(3)  {
    width: 5%;
  }
  table th:nth-child(2) {
    width: 8%;
  }
  table th:nth-child(4) {
    width: 8%;
  }
  table th:nth-child(5) {
    width: 8%;
  }
  table th:nth-child(6) {
    width: 29%;
  }
  table th:nth-child(7) {
    width: 10%;
  }
  table th:nth-child(8),
  table th:nth-child(9),
  table th:nth-child(10) {
    width: 9%
  }
  table tr {
    text-align: center;
  }
  table td {
    padding-top: 14px;
    padding-bottom: 14px;
    a {
      text-decoration: none;
    }
  }
  .img {
    height: 80px;
    width: auto;
  }
  table tbody tr:nth-child(even) {
    background-color: #e5e4e4;
  }
  table tfoot {
    font-weight: bold;
    text-transform: uppercase;
    border-collapse: collapse;
  }
  table tfoot tr {
    background-color: #e5e4e4;
  }
  table tfoot tr td {
    border-right: solid 1px #e5e4e4;
    border-left: solid 1px #e5e4e4;
  }
}