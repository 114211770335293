@import "./../../../../../assets/css/variables.scss";

.contentProducts {
  &_head {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 30px;
    padding: 5px 0 15px 0;
    align-items: center;
    border-bottom: solid 1px #000;
    @media screen and (max-width: $breakpoint-mob-max) {
      gap: 10px;
      padding-top: 0;
      padding-bottom: 10px;
      margin-bottom: 15px;
    }
    .title {
      margin-bottom: 0;
      font-weight: bold;
      font-size: 20px;
      @media screen and (max-width: $breakpoint-mob-max) {
        font-size: 16px;
        line-height: 1;
      }
    }
  }
}