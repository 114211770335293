.imgOptionsWrap {
  .imgOptionsList {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    column-gap: 16px;
    row-gap: 8px;
  }
  .imgOption {
    padding: 24px 12px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #000;
    .img {
      width: 100%;
      & svg {
        width: 100%;
      }
    }
    .wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .name {
        font-weight: 600;
        font-size: 14px;
        text-align: center;
        line-height: 1;
      }
      .subName {
        font-weight: 400;
        font-size: 12px;
        text-align: center;
        line-height: 1;
      }
    }
    img {
      display: block;
      max-width: 100%;
    }
    &:hover,
    &.active {
      background: #b4bec5;
    }
  }
}
