@import "./../../../../../assets/css/variables.scss";

.contentProducts {
  &_design {
    margin-bottom: 16px;
    @media screen and (max-width: $breakpoint-mob-max) {
      margin-bottom: 15px;
    }
    .imgs {
      display: flex;
      justify-content: space-between;
      gap: 40px;
    }
    .imgs .snapshot {
      /* background: #e5e4e4; */
      width: 50%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      .loading {
        width: 100px;
        height: 100px;
      }
    }
    .imgs div > img {
      width: 100%;
      height: auto;
    }
    .blockTitle {
      background-color: #1f2944;
      color: #fff;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 18px;
      padding: 6px;
      padding-left: 16px;
      width: 100%;
      box-sizing: border-box;
      margin-bottom: 20px;
      width: 100%;
      text-align: center;
      @media screen and (max-width: $breakpoint-mob-max) {
        font-size: 16px;
        line-height: 1;
        margin-bottom: 15px;
      }
    }
  }
  
}