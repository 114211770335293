@import "./../../assets/css/variables.scss";

.wrap {
  width: 100%;
  margin-bottom: 3vh;
}

.title {
  margin: 0 0 2.23vh 0;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  @media screen and (max-width: $breakpoint-mob-max) {
    font-size: 20px;
    line-height: 24px;
  }
  @media screen and (max-width: $breakpoint-IPhone-max) {
    font-size: 16px;
    line-height: 19px;
  }
}

.list {
  display: flex;
  flex-direction: row;
  gap: 23px;
  @media screen and (max-width: 1400px) {
    align-items: initial;
    gap: 15px;
  }
  @media screen and (max-width: $breakpoint-mob-max) {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(75px, 1fr));
    width: 100%;
  }
  @media screen and (max-width: $breakpoint-IPhone-max) {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
    width: 100%;
  }
}

.presetButton {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
  padding: 10px 10px 26px 10px;
  height: auto;
  width: 20%;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  cursor: pointer;
  @media screen and (max-width: 1400px) {
    height: auto;
    padding: 8px 8px 15px 8px;
    gap: 10px;
  }
  @media screen and (max-width: $breakpoint-mob-max) {
    width: auto;
    height: auto;
    padding: 5px 5px 10px 5px;
    min-height: 100%;
  }
  &:last-child {
    padding: 10px;
    justify-content: center;
    img {
      width: 30px;
      @media screen and (max-width: $breakpoint-mob-max) {
        width: 25px;
        height: auto;
      }
    }
    p {
      @media screen and (max-width: $breakpoint-mob-max) {
        font-size: 10px;
        line-height: 12px;
      }
    }
  }
  img {
    max-width: 100%;
    max-height: 100%;
  }
  p {
    margin: 0;
    padding: 0 17px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #171E35;
    @media screen and (max-width: 1400px) {
      font-size: 14px;
      line-height: 16px;
      padding: 0 10px;
    }
    @media screen and (max-width: $breakpoint-mob-max) {
      font-size: 10px;
      line-height: 12px;
      padding: 0;
    }
  }
}