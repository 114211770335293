@import "./../../../../assets/css/variables.scss";

.optionCategory {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 16px;
  background: #FFFFFF;
  border: 1px solid rgba(66, 69, 72, 0.15);
  border-radius: 2px;
  cursor: pointer;
  transition: all .2s ease-in-out;
  &:hover {
    background: #B4BEC5;
  }
  @media screen and (max-width: $breakpoint-mob-max) {
    padding: 12px 16px;
    gap: 14px;
  }
  &.disabled {
    opacity: 0.4;
    cursor: auto;
    pointer-events: none;
    background: #FFFFFF !important;
  }
}

.optionCategoryImg {
  position: relative;
  width: 100%;
  padding-top: 74.92%;
  font-size: 0;
  margin-bottom: 15px;
  .imgWrap {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    text-align: center;
    > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.optionCategoryLabel {
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #141E34;
}