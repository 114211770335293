.radioOption {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  gap: 10px;
  background: #FFFFFF;
  border: 1px solid rgba(66, 69, 72, 0.1);
  border-radius: 2px;
  cursor: pointer;
  transition: all .2s ease-in-out;
  &.active {
    border: 1px solid #1F2944;
    box-shadow: inset 0px 0px 0px 1px #1F2944;
  }
}

.radioOptionImg {
  position: relative;
  width: 32px;
  max-width: 32px;
  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.radioOptionLabel {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #141E34;
  text-align: center;
}