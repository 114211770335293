.container {
	.inputs {
		display: flex;

		.boxNumber {
			display: flex;
			align-items: center;
			margin-right: 6px;

			&:last-child {
				margin-right: 0;
			}

			span {
				margin-left: 2px;
				font-size: 14px;
			}
		}
	}

	input {
		width: 42px;
    height: 23px;
		border: 1px solid #77777B;
		border-radius: 2px;
		text-align: center;
	}
}

input[type=number] {
	-moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
