@import "./../../../../../assets/css/variables.scss";

.contentProducts {
  &_collection {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
    margin-bottom: 25px;
    @media screen and (max-width: $breakpoint-mob-max) {
      flex-direction: column;
      margin-bottom: 10px;
    }
    .collectionName,
    .generalInfo {
      width: 50%;
      @media screen and (max-width: $breakpoint-mob-max) {
        width: 100%;
      }
    }
    .collectionName {
      padding-right: 20px;
      @media screen and (max-width: $breakpoint-mob-max) {
        padding-right: 0;
      }
      h3 {
        margin: 0;
        padding-left: 16px;
        @media screen and (max-width: $breakpoint-mob-max) {
          text-align: center;
          padding-left: 0;
        }
      }
      .blockTitle {
        text-align: left;
        @media screen and (max-width: $breakpoint-mob-max) {
          text-align: center;
        }
      }
    }
    .generalInfo {
      padding-top: 5px;
      table  {
        font-size: 15px;
        @media screen and (max-width: $breakpoint-mob-max) {
          font-size: 13px;
        }
      }
      table th,
      table td {
        padding: 2px 0;
      }
      table th {
        text-align: right;
        margin-right: 8px;
      }
      table td {
        padding-left: 16px;
      }
    }
    .blockTitle {
      background-color: #1f2944;
      color: #fff;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 18px;
      padding: 6px;
      padding-left: 16px;
      width: 100%;
      box-sizing: border-box;
      margin-bottom: 20px;
      width: 100%;
      text-align: center;
      @media screen and (max-width: $breakpoint-mob-max) {
        font-size: 16px;
        line-height: 1;
        margin-bottom: 15px;
      }
    }
  } 
}