.radioOptionsSmallWrap {
  margin-bottom: 16px;
  .label {
    margin: 0 0 8px 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
  }
}

.radioOptionsSmallList {
  display: grid;
  grid-template-columns: repeat(auto-fill, 41px);
  column-gap: 16px;
  row-gap: 8px;
  .imgOption {
    width: 41px;
    padding: 12px 7px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 2px;
    transition: all .2s ease-in-out;
    cursor: pointer;
    img {
      display: block;
      max-width: 100%;
    }
    &:hover,
    &.active {
      border: 1px solid #1F2944;
    }
  }
}