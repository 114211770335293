.wallTypeSwitch {
  padding: 16px 0;
  .labelText {
    margin: 0 0 8px 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
  }
  .switchWrap {
    display: flex;
    align-items: center;
    button {
      width: 50%;
      padding: 6px 20px;
      border: 1px solid #77777B;
      background: #FFF;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: rgba(0, 0, 0, 0.25);
      font-family: "ProximaNova", sans-serif;
      cursor: pointer;
      &:first-child {
        border-radius: 2px 0 0 2px;
        border-right: none;
      }
      &:last-child {
        border-radius: 0 2px 2px 0;
        border-left: none;
      }
      &.active {
        border: 1px solid #171E35;
        background: #171E35;
        color: #FFF;
        cursor: default;
        pointer-events: none;
      }
    }
  } 
}