@import "./../../../assets/css/variables.scss";

.sceneControlsBottom3D {
  position: absolute;
  right: 15px;
  bottom: 55px;
  width: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 20px;
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid #31344B;
  border-radius: 2px;
  @media screen and (max-width: $breakpoint-mob-max) {
    right: 50%;
    bottom: 20px;
    transform: translateX(50%);
    flex-direction: row;
    width: auto;
    height: 50px;
    align-items: center;
  }
  > button {
    width: 100%;
    > svg {
      width: 24px;
      height: auto;
    }
    > span {
      text-transform: capitalize;
    }
  }
}